import { SiteInfo } from 'shared'
import { Settings } from '../settings-types'

const mapbox = (settings: Settings, siteInfo: SiteInfo | null) => {
  const apiUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places'

  const defaultParams = {
    access_token: process.env.NEXT_PUBLIC_DOCTORS_MAPBOX_API_KEY,
    country: settings.country || siteInfo?.iso?.slice(-2).toLowerCase(),
    language: settings.language || siteInfo?.iso,
    types: [
      'region',
      'postcode',
      'district',
      'place',
      'locality',
      'neighborhood',
      'address',
    ],
  }

  const resolveStateAbbreviation = (value: string) => {
    const states = {
      al: 'Alabama',
      ak: 'Alaska',
      as: 'American Samoa',
      az: 'Arizona',
      ar: 'Arkansas',
      ca: 'California',
      co: 'Colorado',
      ct: 'Connecticut',
      de: 'Delaware',
      dc: 'District Of Columbia',
      fm: 'Federated States Of Micronesia',
      fl: 'Florida',
      ga: 'Georgia',
      gu: 'Guam',
      hi: 'Hawaii',
      id: 'Idaho',
      il: 'Illinois',
      in: 'Indiana',
      ia: 'Iowa',
      ks: 'Kansas',
      ky: 'Kentucky',
      la: 'Louisiana',
      me: 'Maine',
      mh: 'Marshall Islands',
      md: 'Maryland',
      ma: 'Massachusetts',
      mi: 'Michigan',
      mn: 'Minnesota',
      ms: 'Mississippi',
      mo: 'Missouri',
      mt: 'Montana',
      ne: 'Nebraska',
      nv: 'Nevada',
      nh: 'New Hampshire',
      nj: 'New Jersey',
      nm: 'New Mexico',
      ny: 'New York',
      nc: 'North Carolina',
      nd: 'North Dakota',
      mp: 'Northern Mariana Islands',
      oh: 'Ohio',
      ok: 'Oklahoma',
      or: 'Oregon',
      pw: 'Palau',
      pa: 'Pennsylvania',
      pr: 'Puerto Rico',
      ri: 'Rhode Island',
      sc: 'South Carolina',
      sd: 'South Dakota',
      tn: 'Tennessee',
      tx: 'Texas',
      ut: 'Utah',
      vt: 'Vermont',
      vi: 'Virgin Islands',
      va: 'Virginia',
      wa: 'Washington',
      wv: 'West Virginia',
      wi: 'Wisconsin',
      wy: 'Wyoming',
    }

    type StatesKey = keyof typeof states

    const regex = /(\b[a-zA-Z]{2})$/g
    const matches = value.toLowerCase().match(regex)

    if (matches && matches[1] !== undefined) {
      const fullStateName = Object.prototype.hasOwnProperty.call(
        states,
        matches[1]
      )
        ? states[matches[1] as StatesKey]
        : null

      if (fullStateName) {
        return value.replace(matches[1], fullStateName)
      }
    }

    return value
  }

  const geocode = async (text: string, customParams = {}) => {
    const url = new URL(`${apiUrl}/${text}.json`)
    const params = { ...defaultParams, ...customParams }

    type ParamsKey = keyof typeof params

    Object.keys(params).forEach((key) => {
      if (params[key as ParamsKey]) {
        let param: string | Array<string> = params[key as ParamsKey] ?? ''

        if (Array.isArray(params[key as ParamsKey])) {
          param = (params[key as ParamsKey] as Array<string>).join(',')
        }

        url.searchParams.append(key, param as string)
      }
    })

    return fetch(url.href).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      return response.json()
    })
  }

  const forwardGeocode = (query: string) => {
    // for en-US locale resolve state abbreviation for a better match
    // for example if user inputs Fullerton, CA
    // query will be resolved to Fullerton, California
    const text = encodeURIComponent(
      siteInfo?.iso === 'en-US' ? resolveStateAbbreviation(query) : query
    )

    return geocode(text, { limit: 5 })
  }

  const reverseGeocode = (lat: number, lng: number) => {
    const text = `${lng},${lat}`

    return geocode(text, { limit: 1 })
  }

  return { forwardGeocode, reverseGeocode }
}

export default mapbox
